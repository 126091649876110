import { FC, useState } from 'react';
import classNames from 'classnames';
import { IAnswerResponse } from '../../../../../api/assessorApi/assessorApi.types';
import { ChevronsIcon } from '../../../../components/icons/chevrons';
import { AnswerForm } from './AnswerForm';

interface AnswerContentProps {
  answer: IAnswerResponse
  isActive?: boolean;
}

export const AnswerContent: FC<AnswerContentProps> = ({ isActive, answer }) => {
  const [isShowHelp, setIsShowHelp] = useState(false);


  if (!isActive) {
    return null;
  }

  const handleToggleIsShowHelp = () => {
    setIsShowHelp((prev) => !prev);
  };

  return <div onClick={(e) => e.stopPropagation()} >
    <hr className="my-4" />
    {/* <div className="max-h-[600px] h-full overflow-y-auto scrollbar px-4 pb-3"> */}
    <div className="flex gap-12">
      <div className="space-y-4">
        <div>
          <div className="text-sm text-slate-400 mb-2">Вопрос</div>
          <div className="text-2xl font-semibold font-monserrat text-slate-700">{answer.question}</div>
        </div>
        <div>
          <div className="text-sm text-slate-400 mb-2">Ответ на задание</div>
          <div className="text-base font-normal font-sans text-slate-700">{answer.user_answer}</div>
        </div>
        <div>
          <div className="flex items-center space-x-2 w-fit" onClick={handleToggleIsShowHelp}>
            <div className="text-sm text-slate-500">Подсказка</div>
            <ChevronsIcon className={classNames({ 'rotate-180': isShowHelp })} />
          </div>
          <div className={classNames({ hidden: !isShowHelp }, 'text-slate-400 mb-2')} dangerouslySetInnerHTML={{ __html: answer.assessor_hint }} />
        </div>
      </div>
      {Boolean(answer?.pictures?.length) && <div className="space-y-4 min-w-[35%]">
        {
          (answer.pictures || []).map((pic) => <img className="rounded-3xl" src={pic} alt="" />)
        }
      </div>
      }
    </div>
    <AnswerForm answer={answer} />
    {/* </div> */}
  </div>;
};
