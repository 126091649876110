/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import {
  Button,
  IOption,
  Select,
} from '@unione-pro/unione.assmnt.ui-kit.webapp';
import classNames from 'classnames';
import {
  GradeLevel,
  GradeLevelRu,
  IAnswerResponse,
  IApproveAnswerParams,
} from '../../../../../api/assessorApi/assessorApi.types';
import { appStoreContext } from '../../../../../stores/context.store';
import { PlusIcon } from '../../../../components/icons/plus';
import { TrashIcon } from '../../../../components/icons/trash';
import { answersStore } from '../../AnswersStore';

interface IAnswerFormProps {
  answer: IAnswerResponse;
}

const LEVEL_OPTIONS = [
  {
    label: GradeLevelRu.basic_level,
    value: GradeLevel.basic_level,
  },
  {
    label: GradeLevelRu.normal_level,
    value: GradeLevel.normal_level,
  },
  {
    label: GradeLevelRu.advanced_level,
    value: GradeLevel.advanced_level,
  },
  {
    label: GradeLevelRu.expert_level,
    value: GradeLevel.expert_level,
  },
];

interface Option extends IOption {
  value: string;
}

interface FormDataType {
  major_comp: {
    comp_grade: Option;
    comp: Option;
  };
  comps: {
    competency: Option;
    level: Option;
  }[];
}

export const AnswerForm: FC<IAnswerFormProps> = ({ answer }) => {
  const { approveAnswer } = answersStore;
  const appStore = useContext(appStoreContext);
  const assessorId = appStore.role.data?.id;
  const [compOptions, setCompOptions] = useState(() => (answer?.comps || []).map((comp) => ({
    label: comp.comp_title,
    value: comp.comp_id,
  })));
  const isNew = answer?.status === 'new';
  const currentGrade = useMemo(
    () => (answer.grades || []).find((grade) => grade.assessor_id === assessorId),
    [answer, assessorId],
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormDataType>({
    defaultValues: {
      major_comp: {
        comp_grade:
          isNew || !currentGrade
            ? undefined
            : {
              value: currentGrade.major_comp.comp_grade,
              label: GradeLevelRu[currentGrade.major_comp.comp_grade!],
            },
        comp: {
          label: answer.major_comp.comp_title,
          value: answer.major_comp.comp_id,
        },
      },
      comps: isNew
        ? []
        : answer?.comps?.map((comp) => ({
          level: {
            value: comp.comp_grade,
            label: GradeLevelRu[comp.comp_grade!],
          },
          competency: { label: comp.comp_title, value: comp.comp_id },
        })),
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'comps',
  });

  const competencies
    = useWatch({
      control,
      name: 'comps',
    }) || [];

  useEffect(() => {
    if (competencies.length && answer?.comps?.length) {
      const selectedComps = competencies.map((c) => c.competency?.value);
      const filteredOptions = answer.comps
        .filter((comp) => !selectedComps.includes(comp.comp_id))
        .map((comp) => ({ label: comp.comp_title, value: comp.comp_id }));
      setCompOptions(filteredOptions);
    }
  }, [competencies]);

  const onSubmit = (data: FormDataType) => {
    const parsedData: IApproveAnswerParams = {
      _id: answer._id,
      comps: data.comps.map((competency) => ({
        comp_grade: competency.level?.value as GradeLevel,
        comp_id: competency.competency?.value,
        comp_title: competency.competency?.label,
      })),
      major_comp: {
        comp_grade: data.major_comp.comp_grade.value as GradeLevel,
        comp_id: data.major_comp.comp.value,
        comp_title: data.major_comp.comp.label,
      },
    };

    approveAnswer(parsedData);
  };

  return (
    <form className="flex flex-col mt-6 space-y-6">
      <div className="shadow border rounded-lg">
        <div className="flex justify-between">
          <div className="py-1 px-4 bg-blue-500 bg-opacity-10 text-blue-600 rounded-ee-lg">
            Компетенции задания
          </div>
          <div className="flex items-center gap-4 pr-6 pt-2"></div>
        </div>
        <div className="grid grid-cols-2 p-6 gap-6">
          <div className="w-full flex flex-col">
            <span className="mb-1 text-slate-400">Выберите компетенцию</span>
            <Controller
              name="major_comp.comp"
              control={control}
              render={({ field: levelField }): ReactElement => (
                <Select
                  disabled
                  options={[]}
                  className={classNames(
                    { 'pointer-events-none': !isNew },
                    '!w-full',
                  )}
                  defaultValue={{
                    label: answer.major_comp.comp_title,
                    value: answer.major_comp.comp_id,
                  }}
                  onChange={levelField.onChange}
                />
              )}
            />
          </div>
          <div className="w-full flex flex-col">
            <span className="mb-1 text-slate-400">
              Уровень компетенции (оценка задания)
            </span>
            <Controller
              name="major_comp.comp_grade"
              control={control}
              rules={{ required: 'Укажите уровень компетенции' }}
              render={({ field: levelField }): ReactElement => (
                <Select
                  options={LEVEL_OPTIONS}
                  disabled={!isNew}
                  className={classNames(
                    { 'pointer-events-none': !isNew },
                    '!w-full',
                  )}
                  placeholder="Выберите уровень компетенции"
                  defaultValue={levelField.value}
                  onChange={levelField.onChange}
                />
              )}
            />
            {errors.major_comp?.comp_grade && (
              <p className="text-red-500 mt-1">
                {errors.major_comp?.comp_grade?.message}
              </p>
            )}
          </div>
        </div>
      </div>
      {fields.map((field, index) => (
        <div key={field.id} className="shadow border rounded-lg">
          <div className="flex justify-between">
            <div className="py-1 px-4 bg-green-500 bg-opacity-10 text-green-600 rounded-ee-lg">
              Новая компетенция
            </div>
            {isNew && (
              <div className="flex items-center gap-4 pr-6 pt-2">
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="text-slate-400 hover:text-red-500"
                >
                  <TrashIcon className="h-4" />
                </button>
              </div>
            )}
          </div>
          <div className="grid grid-cols-2 p-6 gap-6">
            <div className="w-full flex flex-col">
              <span className="mb-1 text-slate-400">Выберите компетенцию</span>
              <Controller
                name={`comps.${index}.competency`}
                control={control}
                rules={{ required: 'Укажите компетенцию' }}
                render={({ field: levelField }): ReactElement => (
                  <Select
                    options={compOptions}
                    disabled={!isNew}
                    className={classNames(
                      { 'pointer-events-none': !isNew },
                      '!w-full',
                    )}
                    placeholder="Выберите компетенцию"
                    defaultValue={levelField.value}
                    onChange={levelField.onChange}
                  />
                )}
              />
              {errors.comps?.[index]?.competency && (
                <p className="text-red-500 mt-1">
                  {errors.comps?.[index]?.competency?.message}
                </p>
              )}
            </div>
            <div className="w-full flex flex-col">
              <span className="mb-1 text-slate-400">
                Уровень компетенции (оценка задания)
              </span>
              <Controller
                name={`comps.${index}.level`}
                control={control}
                rules={{ required: 'Укажите уровень компетенции' }}
                render={({ field: levelField }): ReactElement => (
                  <Select
                    options={LEVEL_OPTIONS}
                    disabled={!isNew}
                    className={classNames(
                      { 'pointer-events-none': !isNew },
                      '!w-full',
                    )}
                    placeholder="Выберите уровень компетенции"
                    defaultValue={levelField.value}
                    onChange={levelField.onChange}
                  />
                )}
              />
              {errors.comps?.[index]?.level && (
                <p className="text-red-500 mt-1">
                  {errors.comps?.[index]?.level?.message}
                </p>
              )}
            </div>
          </div>
        </div>
      ))}

      {isNew && (
        <div className="flex flex-col items-end space-y-4">
          {Boolean(answer?.comps?.length > fields.length) && (
            <button
              type="button"
              className="flex items-center font-medium text-green-500 hover:text-green-600"
              onClick={() => {
                append({
                  // @ts-ignore
                  level: undefined,
                  // @ts-ignore
                  competency: undefined,
                });
              }}
            >
              <PlusIcon />
              Добавить компетенцию
            </button>
          )}
          <Button variant="primary" size="sm" onClick={handleSubmit(onSubmit)}>
            Принять задание с выбранной оценкой
          </Button>
        </div>
      )}
    </form>
  );
};
