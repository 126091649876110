import React from 'react';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider } from 'react-jss';
import { BrowserRouter } from 'react-router-dom';
import { appTheme } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/theme';
import { Wrapper } from '@unione-pro/unione.commons.webpack';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './index.scss';
import 'dayjs/locale/ru';

dayjs.extend(utc);
dayjs.locale('ru');

const rootElement = document.getElementById('root');

if (rootElement !== null) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <Wrapper>
        <BrowserRouter>
          <ThemeProvider theme={appTheme}>
            <App />
            <Toaster position="top-right" />
          </ThemeProvider>
        </BrowserRouter>
      </Wrapper>
    </React.StrictMode>,
  );
}
